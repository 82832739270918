<template lang="pug">
.container.is-fluid.box
  .bordered.content.card.my-2
    p This action will force the server to re-deliver all the license(s):
    p Please notes that:
    p.pl-5 - You should received the license(s) in the next hours.
    p.pl-5 - Do not use this feature if you have removed some product.
  .container.is-flex.is-justify-content-space-around.is-align-items-center
    b-button.mt-5(
      type="is-info",
      icon-left="times-circle",
      icon-pack="fas",
      @click="cancelModal()"
    ) Cancel
    b-button.mt-5(
      type="is-danger",
      icon-left="save",
      icon-pack="fas",
      :loading="isLoading",
      @click="forceRegen()"
    ) Confirm
</template>

<script>
  import { snackError } from "../utils/common";
  export default {
    props: {
      installation: {
        type: Object,
        require: true,
      },
      refreshCallback: {
        type: Function,
        default: () => {
          return null;
        },
      },
    },
    data: function () {
      return {
        isLoading: false,
      };
    },
    computed: {
      license: function () {
        return this.installation.license;
      },
    },
    methods: {
      cancelModal() {
        this.$emit("close");
      },
      forceRegen: async function () {
        try {
          this.isLoading = true;
          await this.actions.forceRegenLicense(this.installation.id);
          this.isLoading = false;
          this.closeModal();
        } catch (error) {
          this.isLoading = false;
          snackError(error);
        }
      },
      closeModal() {
        this.refreshCallback();
        this.$emit("close");
        this.$buefy.toast.open({
          message: "license successfully tagged for regeneration",
          type: "is-success",
        });
      },
    },
  };
</script>

<style>
</style>
