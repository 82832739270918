<template lang="pug">
form#installations-form.container.is-fluid.box(
  @submit="addNewInstallation",
  action="/",
  method="put"
)
  H1 Installations :
    .container.is-flex.is-align-items-center
      b-field#id.mt-5.mb-0(
        label="id",
        label-position="on-border",
        v-if="newInstallation.id"
      )
        b-input(
          placeholder="0",
          type="numeric",
          disabled,
          v-model="newInstallation.id"
        )
      b-field#name.mt-5.is-narrow(label="Name :", label-position="on-border")
        b-input(
          placeholder="Name",
          type="text",
          required,
          v-model="newInstallation.name"
        )
  b-field.mt-5(label="Address :", label-position="on-border")
    b-input(
      placeholder="Address",
      type="text",
      required,
      v-model="newInstallation.address"
    )
  .container.is-flex.is-justify-content-space-around.is-align-items-center
    b-button.mt-5(
      native-type="submit",
      type="is-primary",
      icon-left="save",
      icon-pack="fas",
      v-if="!newInstallation.id",
      :disabled="!isValid",
      :loading="isLoading"
    ) Save
    b-button.mt-5(
      native-type="submit",
      type="is-primary",
      icon-left="edit",
      icon-pack="fas",
      v-if="newInstallation.id",
      :disabled="!isValid",
      :loading="isLoading"
    ) Update
    b-button.mt-5(
      type="is-info",
      icon-left="times-circle",
      icon-pack="fas",
      @click="cancelModal()"
    ) Cancel
</template>

<script>
import { snackError } from '../utils/common'
export default {
  components: {
  },
  props: {
    installation: {
      type: Object,
      default: () => {
        return {
          name: null,
          client: null,
        }
      }
    },
    refreshCallback: {
      type: Function,
      default: () => { return null }
    }
  },
  data () {
    return {
      changeDone: false,
      isLoading: false,
      error: null,
      newInstallation: null,
    }
  },
  computed: {
    isValid: function () {
      return this.newInstallation.name && this.newInstallation.client
    }
  },
  watch: {
    date: function () {
      this.changeDone = false
    }
  },
  created: async function () {
    this.newInstallation = { ...{ name: null, client: null }, ...this.installation }
  },
  methods: {
    addNewInstallation: async function (event) {
      event.preventDefault();
      try {
        this.isLoading = true
        let data = this.newInstallation
        let response = await this.actions.createOrUpdateInstallation(data)
        this.changeDone = response.data
        this.error = null
        setTimeout(this.closeModal, 800)
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
        this.error = error.response ? error.response.data.title : error
        snackError(error)
      }
    },
    closeModal () {
      this.refreshCallback()
      this.$emit('close')
      this.$buefy.toast.open({
        message: 'Installations updated',
        type: 'is-success'
      })

    },
    cancelModal () {
      this.$emit('close')
    }
  }
}
</script>

<style>
#id {
  width: 5em;
}
#name {
  width: 100%;
}
</style>
