
// export const BASE_ENDPOINT = "http://localhost:8000/api/"
export const BASE_ENDPOINT = "/api/"

export const API_PRICES = BASE_ENDPOINT + 'prices/'


export const API_CLIENTS = BASE_ENDPOINT + 'clients/'
export const API_INSTALLATIONS = BASE_ENDPOINT + 'installations/'
export const API_INSTALLATIONS_BY_ID = BASE_ENDPOINT + 'installations/{installation_id}'
export const API_INSTALLATIONS_TARGETS = BASE_ENDPOINT + 'installations/{installation_id}/targets'
export const API_ORDERS_BY_INSTALLATION = BASE_ENDPOINT + 'installations/{installation_id}/orders'
export const API_LICENSE_TARGETS = BASE_ENDPOINT + 'license/targets/'
export const API_LICENSE_TARGETS_BY_ID = BASE_ENDPOINT + 'license/targets/{license_target_id}'
export const API_SUBSCRIPTION_PLANS = BASE_ENDPOINT + 'subscription/plans/'
export const API_OPTIONS = BASE_ENDPOINT + 'options/'
export const API_RULES = BASE_ENDPOINT + 'rules/'
export const API_LICENSES = BASE_ENDPOINT + 'licenses/'
export const API_ME = BASE_ENDPOINT + 'me/'
export const API_EXCHANGE_RATE = BASE_ENDPOINT + 'me/exchange/rate'
export const API_FORCE_RENEW_DATE = BASE_ENDPOINT + 'manager/installations/{installation_id}'
export const API_FORCE_JOBS = BASE_ENDPOINT + 'manager/manual_run_jobs'
export const API_ASK_SPECIAL_OFFER = BASE_ENDPOINT + 'offers/ask'
export const API_LIST_SPECIAL_OFFER = BASE_ENDPOINT + 'offers'
export const API_VALIDATE_SPECIAL_OFFER = BASE_ENDPOINT + 'offers/{offers_id}'
