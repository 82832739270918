<template lang="pug">
.container.is-fluid.box(v-if="!hasNonValidatedSpecialOffer && !hasNonAppliedSpecialOffer")
  .bordered.content.card.my-2(v-if="isVisitorUser")
    p.has-text-danger.m-6.is-large You are are not allowed to request a special offer
  .bordered.content.card.my-2(v-else)
    p.subtitle Special offer
    b-field.mt-5(
      label="Free month",
      :type="freeMonthErr ? 'is-danger' : 'is-primary'"
    )
      b-Numberinput(
        min="0"
        placeholder="0",
        v-model="requestValue",
        controls-alignment="left",
        controls-position="compact"
      )
    b-field.mt-5(
      label="Description :",
      label-position="on-border",
      :type="explanationErr ? 'is-danger' : 'is-primary'"
    )
      b-input(placeholder="Description",type="textarea",v-model="explanation")
    b-field.mt-5(
      label="Alma contact :",
      label-position="on-border",
      :type="freeMonthErr ? 'is-danger' : 'is-primary'"
    )
      b-input(placeholder="thisniceguys@almacam.com", type="email", v-model="almaContact")
  .container.is-flex.is-justify-content-space-around.is-align-items-center
    b-button.mt-5(
      type="is-info",
      icon-left="times-circle",
      icon-pack="fas",
      @click="cancelModal()"
    ) Cancel
    b-button.mt-5(
      v-if="!isVisitorUser"
      type="is-primary",
      icon-left="save",
      icon-pack="fas",
      :loading="isLoading",
      @click="saveModifications()"
    ) Confirm
.container.is-fluid.box(v-else)
  .bordered.content.card.my-2
    blockquote
      p
        span {{ formatDateTime(nonAppliedSpecialOffer.created_at) }}
      p {{ nonAppliedSpecialOffer.desc }}
      .is-flex.is-justify-content-space-between
        p.p-0.m-0
          span Requested free month :
          b.pl-2 {{ nonAppliedSpecialOffer.requested_free_month }}
        p.has-text-right.p-0.m-0
          span Alma contact :
          b.pl-2 {{ nonAppliedSpecialOffer.alma_contact }}
  .bordered.content.card.my-2(v-if="hasNonValidatedSpecialOffer")
    p waiting validation
  .bordered.content.card.my-2(v-else)
    div
      p.p-0.m-0.is-flex.is-justify-content-space-around.is-align-items-center
        b-icon(type="is-success" icon="badge-check")
        span.mx-2 {{ nonAppliedSpecialOffer.validated_free_month }} free month
        span.mx-2 {{ formatDateTime(nonAppliedSpecialOffer.validated_at) }}
        span.mx-2 {{ (nonAppliedSpecialOffer.validated_by) }}
      p.py-2.px-4 {{ nonAppliedSpecialOffer.validated_message}}

  .container.is-flex.is-justify-content-space-around.is-align-items-center
    b-button.mt-5(
        type="is-info",
        icon-left="times-circle",
        icon-pack="fas",
        @click="cancelModal()"
      ) Close
</template>

<script>
  import { snackError , formatDateTime } from "../utils/common";

  export default {
    props: {
      installation: {
        type: Object,
        require: true,
      },
      refreshCallback: {
        type: Function,
        default: () => {
          return null;
        },
      },
      hasNonValidatedSpecialOffer:{
        type:Boolean,
        require:true
      },
      hasNonAppliedSpecialOffer:{
        type:Boolean,
        require:true
      },
      nonAppliedSpecialOffer:{
        type: Object,
        default: () => {
          return null;
        },
      },
    },
    data: function () {
      return {
        isLoading: false,
        isVisitorUser : false,
        requestValue:0,
        explanation: "",
        almaContact: "",
        error:null,
        freeMonthErr:false,
        explanationErr:false,
        almaContactErr:false,
        // formatDateTime:formatDateTime
      };
    },
    created:function(){
      if(this.state.user.username != this.installation.client.user.username){
        this.isVisitorUser = true
      }
    },
    methods: {
      formatDateTime:formatDateTime,
      cancelModal() {
        this.$emit("close");
      },
      checkFreeMonth(){
        this.freeMonthErr = false
        if(this.requestValue <= 0){
            this.freeMonthErr = true
          }
        return !this.freeMonthErr
      },
      checkExplanation(){
        this.explanationErr = false
        if(!this.explanation){
            this.explanationErr = true
          }
        return !this.explanationErr
      },
      checkAlmaContact(){
        this.almaContactErr = false
        if(!(
              this.almaContact.endsWith("@almacam.com")
              || this.almaContact.endsWith("@alma.fr")
            )){
            this.almaContactErr = true
          }
        return !this.almaContactErr
      },
      checkData(){
        this.checkFreeMonth()
        this.checkExplanation()
        this.checkAlmaContact()
        return (!this.freeMonthErr && !this.explanationErr && !this.almaContactErr)
      },
      saveModifications: async function () {
        if(this.checkData())
          try {
            this.isLoading = true;
            let data = {
              installation_id:this.installation.id,
              free_month:this.requestValue,
              description:this.explanation,
              alma_contact:this.almaContact,
            }
            let response = await this.actions.askSpecialOffer(data);
            let ok = response.status == 200
            this.closeModal(ok)
            this.error = null;
            this.isLoading = false;
          } catch (error) {
            this.isLoading = false;
            this.error = error.response ? error.response.data.title : error;
            snackError(error);
          }
      },
      closeModal(created) {
        this.refreshCallback(created);
        this.$emit("close");
        this.$buefy.toast.open({
          message: "Validation is on the way",
          type: "is-success",
        });
      },
    },
    watch:{
      requestValue: function(){this.checkFreeMonth()},
      explanation: function(){this.checkExplanation()},
      almaContact: function(){this.checkAlmaContact()},
    }
  };
</script>

<style>
</style>
