/**
 * STATE variables
 * Accessed and watched by all components
 */

export const state = {
  subscriptionPlans: null,
  rules: null,
  indexedSubscriptionPlans: {},
  indexedEquipmentRulesPlan: {},
  indexedSoftwareRulesPlan: {},
  installations: {},
  user: {},
  exchangeRate: null,
  currency: null,
  rate_exchange_date: null,
  identified_as: null
}
