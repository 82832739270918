<template lang="pug">
.block.mx-2.my-1.is-relative
  b-loading(:is-full-page="false" v-model="isComputeFuture" :can-cancel="false")
  table
    thead
      tr
        th license&invoice
        th Period start
        th Period end
        th Sell Price
        th Purchase price
        th Discount
    tbody
      tr(v-for="(line,index) in futureBilling" :class="line.textColor")
        td {{ line.invoice }}
        td {{ line.startDate}}
        td {{ line.endDate}}
        td
          XhrefPrice(:EURprice="line.sellPrice")
        td
          XhrefPrice(:EURprice="line.buyPrice")
        td {{ line.discount}}
      tr(colspan="5")
        td ...
</template>

<script>
  import { DateTime } from "luxon";
  import XhrefPrice from "../partial/XhrefPrice.vue";

  export default {
    components:{
      XhrefPrice
    },
    props: {
      installation: Object,
      resume: Object,
      activationDate:Date,
      hasNonValidatedSpecialOffer:Boolean,
      hasNonAppliedSpecialOffer:Boolean,
      nonAppliedSpecialOffer:Object,
    },
    data:function(){
      return{
        futureBilling:[],
        isComputeFuture:true
      }
    },
    methods: {
      endPeriodDate: function (isoDate) {
        let date = isoDate.plus({ month: 1 });
        return date.toLocaleString();
      },
      nextRenewDate: function (isoDate) {
        let date = isoDate.minus({ days: 10 });
        if (date < DateTime.now()) {
          return "---";
        }
        return date.toLocaleString();
      },
      formatDate: function (isoDate) {
        return isoDate.toLocaleString();
      },
      addLine: function(date,sellPrice,buyPrice,discount,textColor=""){
          let billingLine = {
                  invoice: this.nextRenewDate(date),
                  startDate: this.formatDate(date),
                  endDate: this.endPeriodDate(date),
                  sellPrice: sellPrice,
                  buyPrice: buyPrice,
                  discount: discount,
                  textColor:textColor
            }
            this.futureBilling.push(billingLine)
        },
      refresh: function() {
        this.isComputeFuture = true

        this.futureBilling = []
        let hasLicense = this.installation.license != null

        let date, installFreeMonth, distriFreeMonth, clientFreeMonth
        if(hasLicense){
          let license = this.installation.license
          installFreeMonth = license.install_free_month
          distriFreeMonth = license.distri_free_month
          clientFreeMonth = license.validated_free_month
          if(license.status != 'canceled')
            date = DateTime.fromISO(license.next_renew_date)
          else
            date = DateTime.fromJSDate(this.activationDate)
        }else{
          if(this.hasNonValidatedSpecialOffer){
            clientFreeMonth = this.nonAppliedSpecialOffer.requested_free_month
          }else if (this.hasNonAppliedSpecialOffer){
            clientFreeMonth = this.nonAppliedSpecialOffer.validated_free_month
          }else{
            clientFreeMonth = 0
          }
          installFreeMonth = this.installation.client.user.info.install_free_month
          distriFreeMonth = this.installation.client.user.info.distri_free_month
          date = DateTime.fromJSDate(this.activationDate)
        }
        for (let index = 0; index < installFreeMonth; index++) {
            this.addLine(date, 0, 0, 'Installation')
            date = date.plus({month:1})
        }
        for (let index = 0; index < clientFreeMonth; index++) {
            this.addLine(date, 0, 0, 'Special Offer', this.hasNonValidatedSpecialOffer ?"has-text-info":"")
            date = date.plus({month:1})
        }
        for (let index = 0; index < distriFreeMonth; index++) {
          this.addLine(date, this.resume.total_selling, 0, 'Alma Partnership')
          date = date.plus({month:1})
        }
        //extra line
        this.addLine(date, this.resume.total_selling, this.resume.total_vendor)
        this.isComputeFuture = false
      }
    },
    watch:{
      resume:function(){
        this.refresh()
      },
      activationDate:function(){
        this.refresh()
      }
    },
    created:function(){
      this.refresh()
    }
  };
</script>

<style>
</style>
