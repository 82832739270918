<template lang="pug">
b-skeleton(v-if="!isValidated")
tr(v-else-if="isValidated")
  td
    b-field(
      :type="colorType()",
      :message="option.hasError ? option.errMessage : ''"
    )
      b-select(
        placeholder="Select a new plan",
        expanded,
        v-model="option.plan",
        @input="selectedWatcher",
        style="font-size: 0.9rem"
      )
        option(value="")
        option(
          v-for="(plan, index) in option.plansList",
          v-if="plan.target_type == type",
          :value="plan.id",
          :disabled="!plan.isValid"
        ) 
          div(style="background-color: black") {{ plan.name }}
    p.has-text-info(v-if="option.previous") Replace : {{ actions.getPlanFromID(option.previous.plan).name }}
  td(style="vertical-align: middle")
    p(v-if="option.userPerPlan > 1") {{ ' ' + option.userCount }} / {{ option.userPerPlan }} User
</template>

<script>
export default {
  props: {
    option: Object,
    optionIndex: Number,
    refresher: Number,
    type: String,
    isValidated: Boolean
  },
  methods: {
    selectedWatcher (newValue) {
      this.$emit("on-change", this.optionIndex, newValue)
    },
    colorType () {
      if (this.option.hasError) return 'is-danger'
      if (!this.option.id && this.option.plan != "") return 'is-info'
      return ''
    },
    colorClass () {
      if (!this.option.id && this.option.plan != "") return 'has-text-info'
      if (this.option.previous && this.option.plan != "") return 'has-text-info'
      if (!this.option.id && this.option.plan == "") return 'has-text-grey-lighter'
      return ''
    }
  }
}
</script>

<style>
</style>