import Router from 'vue-router'
// import Licenses from '@/components/Licenses.vue'
import Clients from '@/components/clients/ClientsPage.vue'
// import SubscriptionPlans from '@/components/subscriptionplans/SubscriptionPlans.vue'
// import Prices from '@/components/prices/Prices.vue'
import Installation from '@/components/installations/InstallationPage.vue'
import Orders from '@/components/orders/OrdersPage.vue'
import SpecialOffers from '@/components/specialoffers/SpecialOffersPage.vue'

var routerRoutes = [{
  path: '/',
  name: 'home',
  component: Clients
},
// {
//   path: '/licenses',
//   name: 'licenses',
//   component: Licenses
// },
{
  path: '/clients',
  name: 'clients',
  component: Clients
},
{
  path: '/:installation_id/licenses',
  name: 'installation_licenses',
  component: Installation
},
{
  path: '/:installation_id/orders',
  name: 'installation_orders',
  component: Orders
},
{
  path: '/special/offers',
  name: 'special_offers',
  component: SpecialOffers
},
  // {
  //   path: '/subscriptionplans',
  //   name: 'subscriptionplans',
  //   component: SubscriptionPlans
  // },
  // {
  //   path: '/prices',
  //   name: 'prices',
  //   component: Prices
  // },
]

const router = new Router({ routes: routerRoutes })

export default router
