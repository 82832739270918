<template lang="pug">
.container
  Hero(title="Customers")
    b-input(
      placeholder="Search",
      type="search",
      icon-pack="fas",
      icon="search",
      custom-class="is-rounded",
      icon-clickable,
      v-model="searchValue",
      @icon-click="searchIconClick",
      @keyup.native.enter="searchIconClick",
      icon-right="close-circle",
      icon-right-clickable,
      @icon-right-click="clearIconClick"
    )
    b-button(
      type="is-primary",
      icon-left="user-plus",
      icon-pack="fas",
      @click="showForm()"
    ) Add customer
  b-table-wrapper(
    :endPointActions="sendTo",
    :sendData="sendData",
    @on-error="snackError",
    :key="refresh",
    :searchFilter="searchFilter",
    :table="{ bordered: false, striped: false, narrowed: true }"
  )
    template(v-slot:clients="props")
      installation(:client="props.row", :searchFilter="searchFilter", :refreshCallback="doRefresh")

  b-modal(
    v-model="isComponentModalActive",
    :destroy-on-hide="true",
    aria-operation="dialog",
    aria-modal,
    :props="modalProps",
    :parent="this",
    :component="modalComponent"
  )
</template>

<script>
  import Hero from "../partial/HerosWithButton";
  import bTableWrapper from "../partial/bTableWrapper";
  import ClientFrom from "./ClientsForm";
  import { snackError } from "../utils/common";
  import installation from "./ClientWithInstallations";
  export default {
    components: {
      Hero,
      bTableWrapper,
      installation,
    },
    data: function () {
      return {
        refresh: 0,
        clients: null,
        searchValue: null,
        searchFilter: null,
        sendTo: this.actions.getClients,
        sendData: {},
        snackError: snackError,
        isComponentModalActive: false,
        modalComponent: ClientFrom,
        modalProps: {
          client: {
            id: null,
            name: null,
            description: null,
          },
          refreshCallback: this.doRefresh,
        },
      };
    },
    methods: {
      doRefresh() {
        this.refresh = this.refresh + 1;
        // console.log("refreshing")
      },
      searchIconClick() {
        this.searchFilter = this.searchValue ? this.searchValue.trim() : null;
      },
      clearIconClick() {
        this.searchValue = null;
        this.searchFilter = null;
      },
      showForm(props) {
        if (props) {
          this.modalProps.client = props;
        } else {
          this.modalProps.client = {
            id: null,
            name: null,
            description: null,
          };
        }
        this.isComponentModalActive = true;
      },
    },
  };
</script>

<style>
</style>
