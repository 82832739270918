<template lang="pug">
div
  .is-flex.is-justify-content-space-around.is-align-items-center.is-fullwidth
    .block.mx-2.my-1
      h5 Installation Date :
      p {{ formatDate(installation.license.activation_date) }}
    .block.mx-2.my-1(v-if="installation.license.last_renew_date")
      h5 Last Renewed :
      p {{ formatDate(installation.license.last_renew_date) }}
    .block.mx-2.my-1
      h5 Next Renew :
      p.has-text-danger(v-if="installation.license.status == 'canceled'") Canceled
      p(v-else) {{ nextRenewDate(installation.license.next_renew_date) }}
    .block.mx-2.my-1(v-if="installation.license.last_renew_date")
      h5 Valid until :
      p {{ formatDate(installation.license.next_renew_date) }}
    .block.mx-2.my-1(v-if="buttonLeft")
      slot(name="btn")
  .is-flex.is-justify-content-space-around.is-align-items-center.is-fullwidth.mt-3(
    v-if="!buttonLeft"
  )
    slot(name="btn")
</template>

<script>
  import { DateTime } from "luxon";

  export default {
    props: {
      installation: Object,
      buttonLeft: Boolean,
    },
    methods: {
      nextRenewDate: function (isoDate) {
        let date = DateTime.fromISO(isoDate).minus({ days: 10 });
        if (date < DateTime.now()) {
          return "Planned for today";
        }
        return date.toLocaleString();
      },
      formatDate: function (isoDate) {
        return DateTime.fromISO(isoDate).toLocaleString();
      },
    },
  };
</script>

<style>
</style>
