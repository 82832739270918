<template lang="pug">
form#clients-form.container.is-fluid.box(
  @submit="addNewClient",
  action="/",
  method="put"
)
  H1 Customer :
    .container.is-flex.is-align-items-center
      b-field#id.mt-5.mb-0(
        label="id",
        label-position="on-border",
        v-if="newClient.id"
      )
        b-input(
          placeholder="0",
          type="numeric",
          disabled,
          v-model="newClient.id"
        )
      b-field#name.mt-5.is-narrow(label="Name :", label-position="on-border")
        b-input(
          placeholder="Name",
          type="text",
          required,
          v-model="newClient.name"
        )
  b-field.mt-5(label="Description :", label-position="on-border")
    b-input(placeholder="Description", type="text", v-model="newClient.desc")
  .container.is-flex.is-justify-content-space-around.is-align-items-center
    b-button.mt-5(
      native-type="submit",
      type="is-primary",
      icon-left="save",
      icon-pack="fas",
      v-if="!newClient.id",
      :disabled="!isValid",
      :loading="isLoading"
    ) Save
    b-button.mt-5(
      native-type="submit",
      type="is-primary",
      icon-left="edit",
      icon-pack="fas",
      v-if="newClient.id",
      :disabled="!isValid",
      :loading="isLoading"
    ) Update
    b-button.mt-5(
      type="is-info",
      icon-left="times-circle",
      icon-pack="fas",
      @click="cancelModal()"
    ) Cancel
</template>

<script>
  import { snackError } from "../utils/common";
  export default {
    components: {},
    props: {
      client: {
        type: Object,
        default: () => {
          return {
            name: null,
            desc: null,
          };
        },
      },
      refreshCallback: {
        type: Function,
        default: () => {
          return null;
        },
      },
    },
    data() {
      return {
        changeDone: false,
        isLoading: false,
        error: null,
        newClient: null,
      };
    },
    computed: {
      isValid: function () {
        return this.newClient.name;
      },
    },
    watch: {
      date: function () {
        this.changeDone = false;
      },
    },
    created: async function () {
      this.newClient = { ...{ name: null, desc: null }, ...this.client };
    },
    methods: {
      addNewClient: async function (event) {
        event.preventDefault();
        try {
          this.isLoading = true;
          let data = { name: this.newClient.name, desc: this.newClient.desc };
          let response = await this.actions.createOrUpdateClient(data);
          this.changeDone = response.data;
          this.error = null;
          setTimeout(this.closeModal, 800);
          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
          this.error = error.response ? error.response.data.title : error;
          snackError(error);
        }
      },
      closeModal() {
        this.refreshCallback();
        this.$emit("close");
        this.$buefy.toast.open({
          message: "Customer updated",
          type: "is-success",
        });
      },
      cancelModal() {
        this.$emit("close");
      },
    },
  };
</script>

<style>
  #id {
    width: 5em;
  }
  #name {
    width: 100%;
  }
</style>
