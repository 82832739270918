<template lang="pug">
section.hero.is-small
  .hero-body.is-flex.is-align-items-center
    h1.title.mb-0.left-title(v-if="!logo && title") {{ title }}
    img.image.mb-0.is-24x128.left-title(v-if="logo", :src="logo", :alt="title")
    slot(v-if="!logo && !title" name="left")
    .hero-button.container.is-flex.is-justify-content-flex-end.is-align-items-center
      slot.mx-6
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    logo: {
      type: String,
      default: null
    }
  }
}
</script>

<style>
.hero .is-24x128 {
  max-width: 128px;
  max-height: 24px;
}
.hero .left-title {
  min-width: 10em;
}
.hero .hero-button > * {
  margin: 0 1.5em;
}
</style>
