<template lang="pug">
.container.is-fluid.box
  .bordered.content.card.my-2 
    p License will no longer be renewed, and will end at {{ license.next_renew_date }} :
  .container.is-flex.is-justify-content-space-around.is-align-items-center
    b-button.mt-5(
      type="is-info",
      icon-left="times-circle",
      icon-pack="fas",
      @click="cancelModal()"
    ) Cancel
    b-button.mt-5(
      type="is-danger",
      icon-left="save",
      icon-pack="fas",
      :loading="isLoading",
      @click="saveModifications()"
    ) Confirm
</template>

<script>
import { snackError } from '../utils/common'
export default {
  props: {
    installation: {
      type: Object,
      require: true
    },
    refreshCallback: {
      type: Function,
      default: () => { return null }
    }
  },
  data: function () {
    return {
      isLoading: false
    }
  },
  computed: {
    license: function () { return this.installation.license }
  },
  methods: {
    cancelModal () {
      this.$emit('close')
    },
    saveModifications: async function () {
      let data = { id: this.installation.id }
      try {
        this.isLoading = true
        let response = await this.actions.cancelLicense(data)
        this.changeDone = response.data.results ? true : false
        this.error = null
        this.isLoading = false
        this.closeModal(response.data.results)
      } catch (error) {
        this.isLoading = false
        this.error = error.response ? error.response.data.title : error
        snackError(error)
      }
    },
    closeModal (created) {
      this.refreshCallback(created)
      this.$emit('close')
      this.$buefy.toast.open({
        message: 'license canceled',
        type: 'is-success'
      })
    },
  }
}

</script>

<style>
</style>