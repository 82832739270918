
<template lang="pug">
#app(v-if="!isLoading && !isError")
  .is-hidden {{ git_rev }}
  b-navbar(role="navigation", aria-label="main navigation", shadow)
    template(slot="brand")
      b-navbar-item(tag="router-link", :to="{ path: '/' }")
        img(src="@/assets/logo-almacam-pass_RVB.png", width="112", height="28")

    template(slot="start")
      b-navbar-item(v-if="environnement != 'production'", tag="div")
        small.has-text-info {{ environnement }}
      b-navbar-item(tag="router-link", :to="{ name: 'clients' }") Customers
      b-navbar-item(v-if="state.user.is_staff" tag="router-link", :to="{ name: 'special_offers' }") Special Offers
    template(slot="end")
      b-navbar-item
        a.is-underlined(href="https://almacam.com/products/almacam-pass/" target="_blank") Pass ( Almacam Website )
      .mx-6
      b-navbar-item(v-if="state.user.is_staff", @click="forceJobs()")
        .button.is-warning(:disabled="jobsRunning")
          span Launch renew jobs Manually
          b-icon(icon="atom")
      b-navbar-item(tag="div")
        span {{ state.user.username }}
      b-navbar-item(tag="div" v-if="state.user.is_staff")
        span ( {{ state.identified_as?.username }} )
      b-navbar-item(@click="logout()")
        .button.is-light
          span Logout
          b-icon(icon="sign-out")
  router-view

b-skeleton(v-else-if="isLoading && !isError")
div(v-else-if="!isLoading && isError")
  span Application Error
  p {{ error }}
  b-button(@click="login()") go to login page
</template>

<script>
  import { snackError } from "./utils/common";

  export default {
    name: "AppHome",
    data: function () {
      return {
        jobsRunning: false,
        location: location.host.replace("8080", "8000"),
        environnement: process.env.NODE_ENV,
        git_rev: process.env.VUE_APP_GIT_REVISION,
        isLoading: true,
        isError: false,
        error: null,
      };
    },
    mounted: async function () {
      // redirect to django login page if user not logged
      try {
        await this.actions.getMe(); // get user
      } catch (error) {
        // if any error set error
        this.isLoading = false;
        this.isError = true;
        this.error = error;
      } finally {
        // if user is not defined : redirect ton login page
        if (!this.state.user.username || this.state.user.username == null) {
          this.login();
        }
      }

      // exit if error, especially for redirect, as it's async, this avoid to cache data without an user.
      if (this.isError) return;

      // cache Subscriptions Plan and Rules data
      try {
        await this.actions.cacheData();
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.isError = true;
        this.error = error;
      }
    },
    methods: {
      logout() {
        window.parent.location.href =
          "http://" + this.location + "/accounts/logout/";
      },
      login() {
        window.parent.location.href =
          "http://" + this.location + "/accounts/login/";
      },
      async forceJobs() {
        try {
          this.jobsRunning = true;
          await this.actions.forceJobs();
          await new Promise((r) => setTimeout(r, 3000)); // wait few second before allowing a second jobs to run
          this.jobsRunning = false;
        } catch (error) {
          snackError(error);
          this.jobsRunning = false;
        }
      },
    },
  };
</script>


<style>
</style>
