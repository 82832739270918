<template lang="pug">
form#targets-form.container.is-fluid.box(
  @submit="addNewTarget",
  action="/",
  method="put"
)
  H1(style="text-transform: capitalize") {{ newTarget.target_type == 'equipment' ? 'Machine' : 'User' }} :
    .container.is-flex.is-align-items-center
      b-field#id.mt-5.mb-0(
        label="id",
        label-position="on-border",
        v-if="newTarget.id"
      )
        b-input(
          placeholder="0",
          type="number",
          disabled,
          v-model="newTarget.id"
        )
      b-field#name.mt-5.is-narrow(label="Name :", label-position="on-border")
        b-input(
          placeholder="Name",
          type="text",
          required,
          v-model="newTarget.name"
        )
  b-field.mt-5(
    label="Dongle :",
    label-position="on-border",
    v-if="target.target_type == 'software'",
    controls=false
  )
    b-input(
      placeholder="affected automatically",
      type="text",
      disabled,
      v-model="newTarget.dongle_id",
      controls=false
    )
  b-field.mt-5(label="Description :", label-position="on-border")
    b-input(placeholder="Description", type="text", v-model="newTarget.desc")

  .container.is-flex.is-justify-content-space-around.is-align-items-center
    b-button.mt-5(
      native-type="submit",
      type="is-primary",
      icon-left="plus",
      icon-pack="fas",
      v-if="!newTarget.id",
      :disabled="!isValid",
      :loading="isLoading"
    ) Save
    b-button.mt-5(
      native-type="submit",
      type="is-primary",
      icon-left="edit",
      icon-pack="fas",
      v-if="newTarget.id",
      :disabled="!isValid",
      :loading="isLoading"
    ) Update
    b-tooltip(
      label="Please remove all associated products before",
      :active="!isValid || !canBeDeleted()",
      type="is-danger"
    )
      b-button.mt-5(
        @click="deleteTargetConfirmation()"
        type="is-danger",
        icon-left="trash-xmark",
        icon-pack="fas",
        v-if="newTarget.id",
        :disabled="!isValid || !canBeDeleted()",
        :loading="isLoading"
      ) Delete
    b-button.mt-5(
      type="is-info",
      icon-left="times-circle",
      icon-pack="fas",
      @click="cancelModal()"
    ) Cancel
</template>

<script>
import { snackError } from '../utils/common'
export default {
  components: {
  },
  props: {
    target: {
      type: Object,
      require: true
    },
    refreshCallback: {
      type: Function,
      default: () => { return null }
    }
  },
  data () {
    return {
      changeDone: false,
      isLoading: false,
      error: null,
      newTarget: null,
    }
  },
  computed: {
    isValid: function () {
      let isValid = false || this.newTarget.name
      return isValid
    }
  },
  created: async function () {
    this.newTarget = { ...this.target }
  },
  methods: {
    addNewTarget: async function (event) {
      event.preventDefault();
      try {
        this.isLoading = true
        let data = this.newTarget
        let response = await this.actions.createOrUpdateTarget(data)
        this.changeDone = response.data.results ? true : false
        this.error = null
        this.isLoading = false
        this.closeModal(response.data.results, !this.target.id)
      } catch (error) {
        this.isLoading = false
        this.error = error.response ? error.response.data.title : error
        snackError(error)
      }
    },
    closeModal (created, isNew, deleted) {
      this.refreshCallback(created, isNew, deleted)
      this.$emit('close')
      this.$buefy.toast.open({
        message: 'Targets updated',
        type: 'is-success'
      })
    },
    cancelModal () {
      this.$emit('close')
    },
    deleteTargetConfirmation(){
      this.$buefy.dialog.confirm({
                    message: ` this ${this.target.target_type} : <strong>${this.target.name}</strong> will be deleted.<br>
                    ⚠️ This action cannot be undone. ⚠️<br>
                    are you sure ?`,
                    onConfirm: this.deleteTarget
                })
    },
    async deleteTarget(){
      try {
        this.isLoading = true
        let data = this.newTarget
        let response = await this.actions.deleteTarget(data)
        this.changeDone = response.status === 204 ? true : false
        this.error = null
        this.isLoading = false
        this.closeModal(null, this.target.id, true)
      } catch (error) {
        this.isLoading = false
        this.error = error.response ? error.response.data.title : error
        snackError(error)
      }
    },
    canBeDeleted(){
      let hasOptions = false
      if(this.target.options){
        this.target.options.forEach((option)=>{
          if(option.id) hasOptions = true
        })
      }
      return !hasOptions
    }
  }
}
</script>

<style>
#id {
  width: 5em;
}
#name {
  width: 100%;
}
</style>
