<template lang="pug">
.columns
  .column.is-5.content
    .is-flex.is-align-content-center.is-align-items-center
      slot(name="edit")
      h5 {{ target.name }}
    .subtitle(v-if="target.target_type == 'software'")
      p(:class="target.dongle_id ? '' : 'has-text-danger'") Dongle : {{ target.dongle_id.startsWith('NOT_AFFECTED') ? 'will be affected automatically' : target.dongle_id }}
    div
      p {{ target.desc }}
  .column.content
    table.options-target.table.is-fullwidth
      tbody
        PlanSelect(
          v-for="(option, optionIndex) in target.options",
          :key="optionIndex+'P'+refresher",
          :optionIndex="optionIndex",
          :refresher="refresher",
          :option="option",
          :type="type",
          :isValidated="isValidated",
          @on-change="onChange"
        )
        tr(v-if="target.hasChange")
          td(colspan="3")
            .is-fullwidth.is-flex.is-justify-content-flex-end.is-align-items-center
              b-button.is-outlined.px-6.ml-5(
                type="is-primary",
                icon-left="undo",
                icon-pack="fas",
                @click="undo"
              ) reset
</template>

<script>
import PlanSelect from './PlanSelect'

export default {
  components: {
    PlanSelect
  },
  props: {
    target: Object,
    index: Number,
    refresher: Number,
    type: String,
    isValidated: Boolean
  },
  data: function () {
    return {
      plansSelect: {}
    }
  },
  methods: {
    onChange: function (optionIndex, newValue) {
      this.$emit("on-change", this.index, optionIndex, newValue)
    },
    undo () {
      this.$emit("on-undo", this.index)
    }
  }
}
</script>

<style>
.options-target tbody tr td:first-child {
  width: 50%;
  word-break: break-all;
}
.options-target tbody tr td {
  width: 25%;
  word-break: break-all;
}
</style>
