import { DateTime } from "luxon";
import { SnackbarProgrammatic as Snackbar } from 'buefy'

export function snackError (error, inMessage) {
  let message = error.response ? error.response.status + ' ' : '' // status 404,500 etc ou vide

  if (!inMessage) {
    if (error.response) {
      if (error.response.status == 500) {
        message = message + "INTERNAL SERVER ERROR"
      } else {
        if (error.response.data) {
          let errorMessage = error.response.data
          if (errorMessage.title && errorMessage.type) {
            message = message + errorMessage.title + ' (' + errorMessage.type + ')'
          } else {
            Object.keys(errorMessage).forEach(key => {
              message = message + errorMessage[key]
            });
          }
        } else {
          message = message + error.response.statusText// server error message ou error js
        }
      }
    } else {
      message = message + error
    }


  } else {
    message = message + inMessage // custom message
  }

  Snackbar.open(
    {
      message: message.toString(),
      position: 'is-top',
      duration: 30000,
      type: 'is-danger'
    }
  )
}

export function formatDateTime (value) {
  return value
    ? DateTime.fromISO(value)
      .toLocaleString(DateTime.DATETIME_MED)
      .replaceAll(" ", " ")
    : "_";
}

export function capitalizeFirstLetter (string) {
  return (
    (string && string[0].toUpperCase() + string.slice(1)) ||
    ""
  )
}
