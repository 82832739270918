<template lang="pug">
.container(v-if="state.user.is_staff")
  Hero(title="Special offers")
    b-input(
      placeholder="Search",
      type="search",
      icon-pack="fas",
      icon="search",
      custom-class="is-rounded",
      icon-clickable,
      v-model="searchValue",
      @icon-click="searchIconClick",
      @keyup.native.enter="searchIconClick",
      icon-right="close-circle",
      icon-right-clickable,
      @icon-right-click="clearIconClick"
    )
  b-table-wrapper.vcenter(
    :endPointActions="sendTo",
    :sendData="sendData",
    @on-error="snackError",
    :key="refresh",
    :searchFilter="searchFilter",

  )
    template(v-slot:installation="props")
      b-button(@click="goToInstallation(props.row.installation.id)" type="is-ghost") {{props.row.installation.name}}
    template(v-slot:validated="props")
      .is-flex
        b-button(v-if="!props.row.validated" @click="showForm(props.row)") Validation
        p.p-0.m-0.is-flex.is-justify-content-space-around.is-align-items-center(v-else)
          b-icon(type="is-success" icon="badge-check")
          small.mx-2 {{ formatDateTime(props.row.validated_at) }}
        p.p-0.m-0
          small.mx-2 {{ (props.row.validated_by) }}
  b-modal(
    v-model="isComponentModalActive",
    :destroy-on-hide="true",
    aria-operation="dialog",
    aria-modal,
    :props="modalProps",
    :parent="this",
    :component="modalComponent"
  )
</template>

<script>
  import Hero from "../partial/HerosWithButton";
  import bTableWrapper from "../partial/bTableWrapper";
  import { snackError ,formatDateTime} from "../utils/common";
  import ValidationForm from './ValidationForm.vue';

  export default {
    components: {
      Hero,
      bTableWrapper,
    },
    data: function () {
      return {
        refresh: 0,
        clients: null,
        searchValue: null,
        searchFilter: null,
        sendTo: this.actions.listSpecialOffer,
        sendData: {ordering:"-created_at"},
        snackError: snackError,
        isComponentModalActive: false,
        modalComponent: ValidationForm,
        modalProps: {
          specialOffer: {},
          refreshCallback: this.doRefresh,
        },
      };
    },
    methods: {
      formatDateTime:formatDateTime,
      doRefresh() {
        this.refresh = this.refresh + 1;
      },
      searchIconClick() {
        this.searchFilter = this.searchValue ? this.searchValue.trim() : null;
      },
      clearIconClick() {
        this.searchValue = null;
        this.searchFilter = null;
      },
      goToInstallation(installation_id){
        this.$router.push({ name: 'installation_licenses', params: { installation_id } })
      },
      showForm(props) {
        this.modalProps.specialOffer = props;
        this.isComponentModalActive = true;
      },
    },
  };
</script>

<style>
</style>
