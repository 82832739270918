<template>
  <div id="app">
    <AppHome />
  </div>
</template>

<script>
  import AppHome from "./components/AppHome.vue";

  export default {
    name: "AppMain",
    components: {
      AppHome,
    },
  };
</script>

<style>
  .EUR:after {
    content: " €";
    white-space: nowrap;
    text-align: right;
  }
  .BRL:after {
    content: " R$";
    white-space: nowrap;
    text-align: right;
  }
  .JPY:after {
    content: " ¥";
    white-space: nowrap;
    text-align: right;
  }
  .CAD:after {
    content: " $ C";
    white-space: nowrap;
    text-align: right;
  }
  .USD:after {
    content: " US$";
    white-space: nowrap;
    text-align: right;
  }
  .select select option:disabled {
    color: #c3c3c3;
  }
  /* fix for buefy not compatible with fontawesome v6 */
  .svg-inline--fa {
    height: 1em !important;
  }
  .has-overflow-x-scroll{
    overflow-x: scroll;
  }
  .vcenter table.table tbody tr td {
	line-height: 2.5em;
	vertical-align: middle;
  }
</style>
