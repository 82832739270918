<template lang="pug">
.container.is-fluid.box(v-if="!specialOffer.validated")
  .bordered.content.card.my-2
    p.subtitle
        span {{ capitalizeFirstLetter(specialOffer.installation.client.user.username) }}
        span.pl-2 {{ capitalizeFirstLetter(specialOffer.installation.client.name) }}
        span.pl-2 {{ capitalizeFirstLetter(specialOffer.installation.name) }}
    .content
      blockquote
        p
         span {{ formatDateTime(specialOffer.created_at) }}
        p {{ specialOffer.desc }}
        .is-flex.is-justify-content-space-between
          p.p-0.m-0
            span Requested free month :
            b.pl-2 {{ specialOffer.requested_free_month }}
          p.has-text-right.p-0.m-0
            span Alma contact :
            b.pl-2 {{ specialOffer.alma_contact }}

    .columns
      .column.is-4
        b-field(
          label="Validate Free month",
          label-position="on-border",
          :type="freeMonthErr ? 'is-danger' : 'is-primary'"
        )
          b-Numberinput(
            min="0"
            placeholder="0",
            v-model="requestValue",
            controls-position="compact"
          )
      .column
        b-field(
          label="Comments :",
          label-position="on-border",
          :type="explanationErr ? 'is-danger' : 'is-primary'"
        )
          b-input(placeholder="Comments",type="text",v-model="explanation")
  .container.is-flex.is-justify-content-space-around.is-align-items-center
    b-button.mt-5(
      type="is-info",
      icon-left="times-circle",
      icon-pack="fas",
      @click="cancelModal()"
    ) Cancel
    b-button.mt-5(
      type="is-primary",
      icon-left="save",
      icon-pack="fas",
      :loading="isLoading",
      @click="saveModifications()"
    ) Confirm
.container.is-fluid.box(v-else)
  .bordered.content.card.my-2
    //- p
    //-   b.mx-2 {{ nonValidatedSpecialOffer.requested_free_month }}
    //-   span free month requested on :
    //-   b.mx-2 {{ formatDateTime(nonValidatedSpecialOffer.created_at) }}
    //- p with description : {{ nonValidatedSpecialOffer.desc }}
    //- p waiting validation from : {{ nonValidatedSpecialOffer.alma_contact }}
  .container.is-flex.is-justify-content-space-around.is-align-items-center
    b-button.mt-5(
        type="is-info",
        icon-left="times-circle",
        icon-pack="fas",
        @click="cancelModal()"
      ) Close
</template>

<script>
  import { snackError , formatDateTime, capitalizeFirstLetter } from "../utils/common";

  export default {
    props: {
      specialOffer: {
        type: Object,
        require: true,
      },
      refreshCallback: {
        type: Function,
        default: () => {
          return null;
        },
      },
    },
    data: function () {
      return {
        isLoading: false,
        requestValue:0,
        explanation: "",
        error:null,
        freeMonthErr:false,
        explanationErr:false,
      };
    },
    created:function(){
      this.requestValue = this.specialOffer.requested_free_month
    },
    methods: {
      formatDateTime:formatDateTime,
      capitalizeFirstLetter:capitalizeFirstLetter,
      cancelModal() {
        this.$emit("close");
      },
      checkFreeMonth(){
        this.freeMonthErr = false
        if(this.requestValue < 0){
            this.freeMonthErr = true
          }
        return !this.freeMonthErr
      },
      checkExplanation(){
        this.explanationErr = false
        if(!this.explanation){
            this.explanationErr = true
          }
        return !this.explanationErr
      },
      checkData(){
        this.checkFreeMonth()
        this.checkExplanation()
        return (!this.freeMonthErr && !this.explanationErr && !this.almaContactErr)
      },
      saveModifications: async function () {
        if(this.checkData())
          try {
            this.isLoading = true;
            let data = {
              id:this.specialOffer.id,
              validated_free_month:this.requestValue,
              validated_message:this.explanation,
            }
            let response = await this.actions.ValidateSpecialOffer(data);
            let ok = response.status == 200
            this.closeModal(ok)
            this.error = null;
            this.isLoading = false;
          } catch (error) {
            this.isLoading = false;
            this.error = error.response ? error.response.data.title : error;
            snackError(error);
          }
      },
      closeModal(created) {
        this.refreshCallback(created);
        this.$emit("close");
        this.$buefy.toast.open({
          message: "Validation is on the way",
          type: "is-success",
        });
      },
    },
    watch:{
      requestValue: function(){this.checkFreeMonth()},
      explanation: function(){this.checkExplanation()},
    }
  };
</script>

<style>
</style>
