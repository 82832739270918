import { json } from 'overmind'

export const cacheData = async ({ actions, state }, data) => {
  let subscriptionPlans = null
  if (data?.price_for) {
    subscriptionPlans = await actions.getSubscriptionPlans({ limit: 9999, offset: 0, price_for: data.price_for, force_refresh: true })
  } else {
    subscriptionPlans = await actions.getSubscriptionPlans({ limit: 9999, offset: 0 })
  }
  let rules = await actions.getRules({ limit: 9999, offset: 0 })

  // index subscriptionPlans by id
  state.indexedSubscriptionPlans = {}
  for (let plan of subscriptionPlans) {
    state.indexedSubscriptionPlans[plan.id] = plan
  }

  /**
   * index Rules:
   *  - by type Equipment/Software
   *  - generate inverse rule for incompatibility
   *  - if a joker (NULL) is found, generate all rules.
   */
  state.indexedEquipmentRulesPlan = {}
  state.indexedSoftwareRulesPlan = {}

  let addEqRule = function (rule) {
    if (!state.indexedEquipmentRulesPlan[rule.source_plan]) state.indexedEquipmentRulesPlan[rule.source_plan] = []
    state.indexedEquipmentRulesPlan[rule.source_plan].push(rule)
  }

  let addSoftRule = function (rule) {
    if (!state.indexedSoftwareRulesPlan[rule.source_plan]) state.indexedSoftwareRulesPlan[rule.source_plan] = []
    state.indexedSoftwareRulesPlan[rule.source_plan].push(rule)
  }

  let inverseRule = function (rule) {
    let invRule = {
      id: null,
      source_plan: rule.target_plan,
      rule: rule.rule,
      target_plan: rule.source_plan
    }
    return invRule
  }

  for (let rule of rules) {
    if (rule.rule == "require") {
      if (state.indexedSubscriptionPlans[rule.target_plan].target_type == "equipment") {
        addEqRule(rule)
      } else {
        addSoftRule(rule)
      }
    }
    if (rule.rule == "require_license") {
      if (state.indexedSubscriptionPlans[rule.target_plan].target_type == "equipment") {
        console.error("Equipment rule in between license", rule)
      } else {
        addSoftRule(rule)
      }
    }
    if (rule.rule == "incompatibility") {
      if (rule.target_plan) {
        if (state.indexedSubscriptionPlans[rule.target_plan].target_type == "equipment") {
          addEqRule(rule)
          addEqRule(inverseRule(rule))
        } else {
          addSoftRule(rule)
          addSoftRule(inverseRule(rule))
        }
      } else {
        for (let plan of subscriptionPlans) {
          let developJokerRule = {
            id: null,
            source_plan: rule.source_plan,
            rule: rule.rule,
            target_plan: plan.id
          }
          addSoftRule(developJokerRule)
          addSoftRule(inverseRule(developJokerRule))
        }
      }
    }
  }
}

export const getCacheSubscriptionPlans = function ({ state }) {
  return json(state.subscriptionPlans)
}
export const getCacheIndexedSubscriptionPlans = function ({ state }) {
  return json(state.indexedSubscriptionPlans)
}
export const getCacheRules = function ({ state }) {
  return json(state.rules)
}
export const getCacheIndexedEquipmentRulesPlan = function ({ state }) {
  return json(state.indexedEquipmentRulesPlan)
}
export const getCacheIndexedSoftwareRulesPlan = function ({ state }) {
  return json(state.indexedSoftwareRulesPlan)
}


export const getClients = async ({ effects }, data) => {
  let response = await effects.webPassAPI.listClients(data)
  let headers = [{
    field: 'clients',
    label: " ",
    sortable: false,
    format: { type: 'slot' }
  }]
  return {
    headers: headers,
    count: response.data.count,
    results: response.data.results
  }

}

export const listOrdersByInstallation = async ({ effects }, data) => {
  let response = await effects.webPassAPI.listOrdersByInstallation(data)
  let results = response.data.results
  let headers = [{
    field: 'orders',
    label: 'Orders',
    sortable: false,
    format: { type: 'slot' }
  }]
  /*
      explode data from [ order { targets:[ { options:[] }]} ]
         to [order target option] for displaying in table
  */
  let orderRowSpans = {}
  let targetRowSpans = {}
  for (let order of results) {
    orderRowSpans[order.id] = 0
    for (let target of order.targets) {
      orderRowSpans[order.id] += target.options.length
      targetRowSpans[target.id] = target.options.length
    }
  }
  for (let order of results) {
    order.rowData = []
    let orderIndex = 0
    for (let target of order.targets) {
      let targetIndex = 0
      for (let option of target.options) {
        order.rowData.push(
          {
            id: order.id,
            status: order.status,
            generated_by: order.generated_by,
            total_price: order.total_price,
            created_at: order.created_at,
            dongle_id: target.dongle_id ? target.dongle_id : "-",
            target_name: target.target_name,
            state: target.state,
            id_weborders: target.id_weborders,
            product: option.product,
            vendor_price: option.vendor_price,
            client_price: option.client_price,
            orderIndex: orderIndex,
            targetIndex: targetIndex
          }
        )
        orderIndex++
        targetIndex++
      }
    }
  }
  return {
    headers: headers,
    count: response.data.count,
    results: results
  }

}

export const createOrUpdateClient = async ({ effects }, data) => {
  let response = await effects.webPassAPI.createOrUpdateClient(data)
  return response
}


export const getSubscriptionPlans = async ({ state, effects }, data) => {
  let force_refresh = false
  if (data.force_refresh) {
    force_refresh = true,
      delete data.force_refresh
  }
  try {
    if (force_refresh || state.subscriptionPlans == null) {
      let response = await effects.webPassAPI.listSubscriptionPlans(data)
      state.subscriptionPlans = response.data.results
    }
  } catch (err) {
    if (err.response.status == 404) {
      state.subscriptionPlans = []
    } else {
      throw err
    }
  }
  return json(state.subscriptionPlans)
}

export const getRules = async ({ state, effects }, data) => {

  try {
    if (state.rules == null) {
      let response = await effects.webPassAPI.listRules(data)
      state.rules = response.data.results
    }
  } catch (err) {
    if (err.response.status == 404) {
      state.rules = []
    } else {
      throw err
    }
  }
  return json(state.rules)
}



export const getPlanFromID = function ({ state }, id) {
  if (state.subscriptionPlans == null) return null
  return json(state.indexedSubscriptionPlans[id])
}

export const createOrUpdateSubscriptionPlan = async ({ effects }, data) => {
  let response = await effects.webPassAPI.createOrUpdateSubscriptionPlan(data)
  return response
}

export const createOrUpdateOptionList = async ({ state, effects }, data) => {
  let installationID = data.installationID
  delete data.installationID
  let response = await effects.webPassAPI.createOrUpdateOptionList(data)
  if (state.installations[installationID].license)
    state.installations[installationID].license.modified = true
  return response
}

export const getPrices = async ({ effects }, data) => {
  let response = await effects.webPassAPI.listPrices(data)

  return {
    count: response.data.length,
    results: response.data
  }
}

export const createOrUpdatePrice = async ({ effects }, data) => {
  let response = await effects.webPassAPI.createOrUpdatePrice(data)
  return response
}

export const createOrUpdateInstallation = async ({ state, effects }, data) => {
  let response
  if (!data.id) {
    response = await effects.webPassAPI.createInstallation(data)
  } else {
    response = await effects.webPassAPI.UpdateInstallation(data)
  }
  state.installations[response.data.results.id] = response.data.results
  return response
}

export const createOrUpdateTarget = async ({ effects }, data) => {
  let response = await effects.webPassAPI.createOrUpdateTarget(data)
  return response
}

export const deleteTarget = async ({ effects }, data) => {
  let response = await effects.webPassAPI.deleteTarget(data)
  return response
}

export const listInstallationTargets = async ({ effects }, data) => {
  let response = await effects.webPassAPI.listInstallationTargets(data)
  for (let target of response.data.results) {
    target.hasChange = false
  }
  return response.data.results
}

export const addInstallationToCache = async ({ state }, data) => {
  state.installations[data.id] = data
}

export const getInstallationById = async ({ state, effects, actions }, data) => {
  let install_id = data.id
  let force_refresh = data.force_refresh ? data.force_refresh : false

  if (!force_refresh && state.installations[install_id]) {
    await actions.refreshPlanPriceBasedOnReseller(state.installations[install_id].client.user)
    return json(state.installations[install_id])
  } else {
    let response = await effects.webPassAPI.getInstallationById(data)
    state.installations[install_id] = response.data.results
    await actions.refreshPlanPriceBasedOnReseller(state.installations[install_id].client.user)
    return json(state.installations[install_id])
  }
}

export const refreshPlanPriceBasedOnReseller = async ({ state, actions }, iUser) => {
  if (state.user.is_staff) {
    if (state.identified_as?.username != iUser.username) {
      state.identified_as = json(iUser)
      state.user.info = json(iUser.info)
      await actions.getExchangeRate({ price_for: state.identified_as.username })
      await actions.cacheData({ price_for: state.identified_as.username, force_refresh: true })
    }
  }
}

export const createOrUpdateLicence = async ({ effects }, data) => {
  let response = await effects.webPassAPI.createOrUpdateLicence(data)
  return response.data.results
}

export const cancelLicense = async ({ effects }, data) => {
  let response = await effects.webPassAPI.cancelLicense(data)
  return response
}

export const forceRegenLicense = async ({ effects }, data) => {
  let response = await effects.webPassAPI.forceRegenLicense(data)
  return response
}
export const getMe = async ({ actions, state, effects }) => {
  state.user = {}
  let response = await effects.webPassAPI.getMe()

  if (response.data.results) {
    state.user = { ...response.data.results }
    if (!state.user.info) {
      state.user.info = {
        currency: "EUR",
        install_free_month: 0,
        distri_free_month: 0,
      }
    }
  }

  await actions.getExchangeRate()

  return true
}

export const forceJobs = async ({ effects }) => {
  await effects.webPassAPI.forceJobs()
}

export const forceRenewDate = async ({ effects }, data) => {
  await effects.webPassAPI.forceRenewDate(data)
}

export const getExchangeRate = async ({ state, effects }, data) => {
  if (state.user.info.currency != "EUR") {

    if (!state.exchangeRate || state.currency) {
      let response = await effects.webPassAPI.getExchangeRate(data)
      state.exchangeRate = response.data.results.rate
      state.currency = response.data.results.currency
      state.rate_exchange_date = response.data.results.rate_exchange_date
    }

  } else {
    state.exchangeRate = "EUR"
    state.currency = 1
    state.rate_exchange_date = 0
  }
}

export const convert = ({ state }, { value, revertConversion }) => {
  if (!state.exchangeRate) state.exchangeRate = 1
  if (!value) return 0
  if (!revertConversion) return (value * state.exchangeRate).toFixed(2)
  if (revertConversion) return (value / state.exchangeRate).toFixed(2)
}


export const askSpecialOffer = async ({ effects }, data) => {
  let response = await effects.webPassAPI.askSpecialOffer(data)
  return response
}

export const listSpecialOffer = async ({ effects }, data) => {
  let response = await effects.webPassAPI.listSpecialOffer(data)
  let headers = [
    {
      field: "installation.client.user.username",
      label: "Reseller",
      sortable: true,
      format: {
        type: "str",
      }
    },
    {
      field: "installation.client.name",
      label: "Client",
      sortable: true,
      format: {
        type: "str",
      }
    },
    {
      field: "installation",
      label: "Installation",
      sortable: true,
      format: {
        type: "slot",
      }
    },
    {
      field: "requested_free_month",
      label: "Requested Free Month",
      sortable: true,
      format: {
        type: "int",
        format: "int64"
      }
    },
    {
      field: "validated_free_month",
      label: "Validated Free Month",
      sortable: true,
      format: {
        type: "int",
        format: "int64"
      }
    },
    // {
    //   field: "desc",
    //   label: "Desc",
    //   sortable: true,
    //   format: {
    //     type: "str"
    //   }
    // },
    {
      field: "alma_contact",
      label: "Alma Contact",
      sortable: true,
      format: {
        type: "str",
        format: "email"
      }
    },
    {
      field: "created_at",
      label: "Created At",
      sortable: true,
      format: {
        type: "datetime",
        format: "date-time"
      }
    },
    {
      field: "validated",
      label: "Validated",
      sortable: true,
      format: {
        type: "slot"
      }
    },
  ]

  return {
    headers: headers,
    count: response.data.count,
    results: response.data.results
  }

}

export const ValidateSpecialOffer = async ({ effects }, data) => {
  let response = await effects.webPassAPI.ValidateSpecialOffer(data)
  return response
}
