<template lang="pug">
b-skeleton(v-if="isInit", height="100%")
.b-table-wrapper(v-else, :id="idTable")
  //- b-table component : all pre fixed value can be override with the table object : see props for more information.
    TODO : pre-selection de ligne : fct de selection // mise en surbrilliance
  b-table(
    v-if="isValid && !empty",
    icon-pack="fas",
    :data="rowData",
    :per-page="perPage",
    :loading="isLoading",
    :total="total",
    v-bind="tableCustomization",
    @page-change="onPageChange",
    :default-sort="defaultSort",
    :default-sort-direction="defaultSortDirection",
    @sort="onSort",
    @update:checkedRows="onCheck",
    backend-sorting
  )
    //- b-table-column component customized with hearers objects : see props for more information.
      note on visible :
        - if the field name is 'id' : the showId param is used (default false)
        - if visible is defined, the defined value is used 
        - if visible is undefined, visible is set to true  
    b-table-column(
      v-slot="props",
      v-for="(col, index) in columnHeaders",
      :key="index",
      v-bind="col",
      :visible="col.field == 'id' ? showId : col.visible != undefined ? col.visible : true"
    )
      //- formatter for date and datetime
      span(v-if="col.format.type == 'date'") {{ formatDate(cellsValue(props.row, col.field)) }}
      span(v-else-if="col.format.type == 'datetime'") {{ formatDateTime(cellsValue(props.row, col.field)) }}

      //- boolean with icons : see props for customization
      .has-text-success(
        v-else-if="col.format.type == 'bool' && showBooleanAsIcons && cellsValue(props.row, col.field)"
      ) 
        b-icon(:icon="iconBooleanTrue", :size="iconSize")
      .has-text-danger(
        v-else-if="col.format.type == 'bool' && showBooleanAsIcons && !cellsValue(props.row, col.field)"
      )
        //- this empty div also prevent icon not being reactive ( https://github.com/buefy/buefy/issues/960 )
        div(v-if="showFalse")
          b-icon(:icon="iconBooleanFalse", :size="iconSize") 

      //- formatter for list of string
      span(v-else-if="col.format.type == 'List[str]'") {{ formatList(cellsValue(props.row, col.field)) }}

      //- slot : the slot are named base on the column field see comments bellow for usage and example
      slot(
        v-else-if="col.format.type == 'slot' || col.format.format == 'slot'",
        :name="col.field",
        v-bind:row="props.row",
        v-bind:col="col.field"
      ) 

      span(v-else) {{ cellsValue(props.row, col.field) }}

    //- slot : 
    template(v-slot:detail="props")
      slot(name="row-detail", :row="props.row", :index="props.index") 

    //- custom pagination with item per page selector
    template(slot="bottom-left")
      b-select(
        v-model="perPage",
        :disabled="!isPaginated && showItemsPerPageSelector"
      )
        option(v-for="(v, index) in perPageOptions", :key="index", :value="v") {{ v }}
      b-button.button.is-small.is-light.mx-6(
        v-if="allowExport",
        icon-left="file-csv",
        icon-pack="fas",
        @click="downloadAsCSV()"
      ) Export as csv

  p(v-if="empty")
    span no data found
  //- Debug mode
  .has-text-danger(v-if="isDebug")
    p headers : {{ this.columnHeaders != null }}
    p data : {{ this.rowData != null }}
    p total : {{ this.total != null }}
    p error: {{ error }}
</template>

<script>
/**  
  Slots          
  you have to warp yours custom component inside a template element with v-slot:FIELD="props" where FIELD is the column field value.
  the component can access 
    - props.row : all the row data (including hidden columns)
    - props.field : the columns field
    so you can use props.row[props.col] for the cell value, or props.row if you want the entire row object.

  examples : (replace FIELD with the column field value)

  - simple button :

      b-table-wrapper
        template(v-slot:FIELD="props")
          b-button(@click="doSomething(props.row)") 
            translate Edit 

  - button appears only on certain row :

      b-table-wrapper
        template(v-slot:FIELD="props")
          b-button( 
            v-if="props.row.is_active" 
            @click="doSomething(props.row)"
            ) 

  - custom render  : (split string base on ' | ', and render as multi-line inside the cell)

      b-table-wrapper
        template(v-slot:FIELD="props")
            p(
              v-for="(row, index) in props.row[props.col].split(',')",
              style="line-height: 1em"
            ) {{ row.replaceAll('|', ' | ') }}
  
  - class for an entire row :
    use row-class from buefy : a function that return the class to apply, fct receive row(object), and index (int) (see buefy documentation for more information)
      simple example (random text color blue/red):
      // template 
      b-table-wrapper(
        :table="{ rowClass: reddish }",
        ...
        )
        
      // methods
        reddish: function (row, index) {
            return Math.random() > 0.5 ? "has-text-danger" : "has-text-info"
          },
      
*/
import { DateTime } from 'luxon'

export default {
  props: {
    /** can be helpful for setting/debugging a table*/
    isDebug: {
      type: Boolean,
      default: false
    },
    /** Table configuration object
     * see : https://buefy.org/documentation/table/ for all possibility
     * default applied configuration is : 
     *    striped: true,
     *    hoverable: true,
     *    sortIcon: 'arrow-up',
     *    paginated: true,
     *    paginationRounded: true,
     *    
     * 
    */
    table: {
      type: Object,
      default: () => { return {} }

    },
    /**
     * Overmind Action for backend sorting and pagination
     * Actions must return a object with :
     *  - count : total of item
     *  - data : row data to be displayed
     *  - headers : the columns définitions (if not provided)
     */
    endPointActions: {
      type: Function,
      default: null
    },

    /** data to send at end point 
     *  + limit, offset and ordering 
    */
    sendData: {
      type: Object,
      default: () => { return {} }
    },


    /** items per pge option*
     *  - showItemsPerPageSelector : default : true , if false hide the item per page selector 
     *  - itemsPerPage : default 10
     *  - perPageOptions : options in the selector menu
     */
    showItemsPerPageSelector: {
      type: Boolean,
      default: true
    },
    itemsPerPage: {
      type: [Number, String],
      default: 10
    },
    perPageOptions: {
      type: Array,
      default: () => { return ["5", "10", "20", "50", "100"] }
    },
    /**  tableHeaders : array of object to describe eah columns
    *      - field (required) :string   Property of the object the column is attributed, used for sorting
    *      - label (required) : string  Column header displayed text
    *      - format (important) : string [date, datetime, boolean, slot] use to format date, boolean, or adding slot for custom render. 
    *      - any of the buefy param for table-column : https://buefy.org/documentation/table/   api : https://buefy.org/documentation/table/#api-view
    *      most common are : 
    *           sortable : true for activate sorting on the column
    *           visible :  false to hide the column (data are still present), especially useful for Ids
    *           numeric : for numeric display (Align the cell content to the right, sort icon on left)
    *           centered : center header and content.
    */
    headers: {
      type: Array,
      default: null
    },

    /** tableData array of object */
    tableData: {
      type: Array,
      default: null
    },
    /** wrapper id : use document.getElementById(***tableId***).getElementsByTagName("table")[0] to get the html table element*/
    tableId: {
      type: String,
      default: null
    },
    /** config for Boolean 
     * showBooleanAsIcons : true/false , if true boolean will appears as green (is-success) / red (is-danger) icons
     * iconSize : String [ is-small, is-medium, is-large ] size of the icons
     * iconBooleanTrue : string , icon for true
     * iconBooleanFalse :string , icon for false
     * showFalse : true/false : if false, only the true icons appears , the false are left blank.
     *  */
    showBooleanAsIcons: {
      type: Boolean,
      default: true
    },
    iconBooleanTrue: {
      type: String,
      default: 'check-circle'
    },
    iconBooleanFalse: {
      type: String,
      default: 'times-circle'
    },
    showFalse: {
      type: Boolean,
      default: false
    },
    iconSize: {
      type: String,
      default: 'is-medium'
    },

    /**
     * showId : true/false, if false the column id will be displayed
     */
    showId: {
      type: Boolean,
      default: false
    },

    /**
     *  searchFilter : added to data send to action if set.
     */
    searchFilter: {
      type: String,
      default: null
    },

    /**
     *  allowExport : show the export as csv button.
     */
    allowExport: {
      type: Boolean,
      default: false
    },
  },
  data: function () {
    return {
      isInit: true,
      isLoading: true,
      total: 0,
      currentPage: 1,
      perPage: 10,
      rowData: null,
      isValid: false,
      columnHeaders: null,
      defaultSort: null,
      defaultSortDirection: 'desc',
      error: null,
      pagination: {},
      empty: false
    }
  },
  computed: {
    idTable: function () {
      return this.tableId ? this.tableId : "table-" + this._uid
    },
    isPaginated: function () {
      return this.tableCustomization.paginated ? this.tableCustomization.paginated : false
    },
    isBackendPaginated: function () {
      return this.tableCustomization.backendPagination ? this.tableCustomization.backendPagination : false
    },
    tableCustomization: function () {
      return {
        ...{
          striped: true,
          hoverable: true,
          sortIcon: 'arrow-up',
          paginated: true,
          paginationRounded: true,
          backendPagination: true,
          backendSorting: true,
        }, ...this.table
      }
    }
  },
  watch: {
    searchFilter: function () {
      this.loadContent()
    },
  },
  mounted: async function () {
    this.perPage = this.itemsPerPage
    if (this.sendData.ordering) {
      if (this.sendData.ordering.startsWith('-')) {
        this.defaultSort = [this.sendData.ordering.substring(1), 'desc']
      } else {
        this.defaultSort = [this.sendData.ordering, 'asc']
      }
    }
    if (this.headers != null) this.columnHeaders = this.headers
    if (this.tableData == null && this.endPointActions) {
      await this.loadContent()
      this.$watch('perPage', this.loadContent)
    }
    if (this.tableData != null && !this.endPointActions) {
      this.total = this.tableData.length
      this.rowData = this.tableData
      this.isLoading = false
    }
    this.isInit = false
    this.isValid = (this.columnHeaders != null && this.rowData != null && this.total != null)
  },
  methods: {
    cellsValue: function (row, field) {
      if (field.includes('.')) {
        let nested = field.split('.')
        let returnValue = row
        nested.forEach(e => {
          returnValue = returnValue[e]
        }
        )
        return returnValue
      }
      return row[field]

    },
    formatDateTime: function (value) { return value ? DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED).replaceAll(' ', ' ') : "_" },
    formatDate: function (value) { return value ? DateTime.fromISO(value).toLocaleString(DateTime.DATE_MED).replaceAll(' ', ' ') : "_" },
    formatList: function (value) { return value ? value.toString().replaceAll(',', ' , ') : '_' },
    onPageChange: function (newPage) {
      this.currentPage = newPage
      if (this.endPointActions && this.tableCustomization.backendPagination) {
        this.loadContent()
      }
    },
    onSort: function (sortField, sortOrder) {
      if (this.endPointActions && this.tableCustomization.backendSorting) {
        this.ordering = sortOrder == 'asc' ? sortField : '-' + sortField
        this.pagination.ordering = this.ordering
        this.loadContent()
      }
    },
    onCheck: function (checked) {
      this.$emit('selection-change', checked)
    },
    loadContent: async function () {
      if (this.endPointActions) {
        try {
          this.isLoading = true
          if (this.isBackendPaginated) {
            this.pagination.limit = this.perPage
            this.pagination.offset = this.perPage * (this.currentPage - 1)
          } else {
            delete this.pagination.limit
            delete this.pagination.offset
          }
          if (this.searchFilter) { this.pagination.search = this.searchFilter }
          else { delete this.pagination.search }
          this.isLoading = true
          let response = await this.endPointActions({ ...this.sendData, ...this.pagination })
          if (this.headers == null) this.columnHeaders = response.headers
          this.rowData = response.results
          this.total = response.count
          this.empty = false
          this.isLoading = false
        } catch (error) {
          if (error.response.status) {
            if (error.response.status == 404) {
              this.isLoading = false
              this.empty = true
              return
            }
          }
          this.isLoading = false
          this.rowData = []
          this.total = 0
          this.error = error
          this.$emit("on-error", error)
        }
      }
    },
    // adapted from source : https://stackoverflow.com/questions/15547198/export-html-table-to-csv
    downloadAsCSV: function (separator = ';') {
      let tableEle = document.getElementById(this.idTable).getElementsByTagName("table")[0]
      let csvRows = this.parseTable(tableEle, separator)
      var a = document.createElement("a")
      a.style = "display: none; visibility: hidden" //safari needs visibility hidden
      a.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvRows.join('\n'))
      a.download = 'export-' + DateTime.local().toFormat('yyyyMMddhhmmss') + '.csv'
      document.body.appendChild(a)
      a.click()
      a.remove()
    },
    parseTable (tableDomElement, separator) {
      let csvRows = []
      //only get direct children of the table in question (thead, tbody)
      Array.from(tableDomElement.children).forEach(function (node) {
        //using scope to only get direct tr of node
        node.querySelectorAll(':scope > tr').forEach(function (tr) {
          let hasNestedTable = false
          let csvLine = []
          //again scope to only get direct children
          tr.querySelectorAll(':scope > th,td').forEach(function (td) {
            //clone as to not remove anything from original
            let copytd = td.cloneNode(true)
            let nestedTable = td.querySelectorAll(':scope > table')
            if (nestedTable.length > 0) {
              hasNestedTable = true
              csvRows = [...csvRows, ...this.parseTable(nestedTable[0], separator)]
            } else {
              let data
              if (copytd.dataset.val) {
                data = copytd.dataset.val.replace(/(\r\n|\n|\r)/gm, '')
              }
              else {
                data = copytd.textContent.replace(/(\r\n|\n|\r)/gm, '')
              }
              data = data.replace(/(\s\s)/gm, ' ').replace(/"/g, '""')
              csvLine.push('"' + data + '"')
            }
          }.bind(this))
          if (!hasNestedTable) csvRows.push(csvLine.join(separator))
        }.bind(this))
      }.bind(this))
      return csvRows
    }
  }

}
</script>

<style>
</style>