import { state } from './state'
import * as actions from './actions'
import * as effects from './effects'
import { createOvermind } from 'overmind'
import { createPlugin } from 'overmind-vue'

const config = {
    state,
    actions,
    effects
}
var overmind = null
if (process.env.NODE_ENV === 'development') {
    overmind = createOvermind(
        config,
        {
            devtools: true // 'localhost:3031'
        }
    )
} else {
    overmind = createOvermind(config)
}

export const OvermindPlugin = createPlugin(overmind)