<template lang='pug'>
div
  p.has-text-right.pr-2.my-0(:class="displayCurrency") {{ Number.parseFloat(currencyPrice).toFixed(2) }}
  p.has-text-right.pr-2.content.EUR.is-small.has-text-grey(v-if="displayCurrency != 'EUR'") {{ Number.parseFloat(smallEurPrice).toFixed(2) }}
</template>

<script>
export default {
  props: {
    EURprice: {
      type: Number,
      required: true
    },
    convert: {
      type: Boolean,
      default: true
    },
    currency: {
      type: String,
      default: ""
    },
  },
  data: function () {
    return {
      // var : value
    }
  },
  computed: {
    displayCurrency: function(){
      return this.currency === "" ? this.state.user.info.currency : this.currency
    },
    currencyPrice: function () {
      if (this.displayCurrency == 'EUR' || !this.convert) {
        return this.EURprice
      }
      return this.actions.convert({ value: this.EURprice, revertConversion: false })
    },
    smallEurPrice: function () {
      if (this.convert) return this.EURprice
      return this.actions.convert({ value: this.EURprice, revertConversion: true})
    }
  }
}
</script>

<style>
</style>
